import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListPbm from "@/components/pbm/ListPbm.vue";

export default {
  components: {
    HeaderRegister,
    ListPbm,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
